<template>
  <component :is="icon" class="icon mt-1" :class="size" />
</template>

<script>
import Appointment from '@/assets/icons/appointment.svg'
import Procedure from '@/assets/icons/procedure.svg'
import Telemedicine from '@/assets/icons/telemedicine.svg'
import Return from '@/assets/icons/return.svg'
import Exam from '@/assets/icons/exam.svg'
import Product from '@/assets/icons/product.svg'
import Surgical from '@/assets/icons/surgical.svg'

export default {
  components: {
    Procedure,
    Telemedicine,
    Appointment,
    Return,
    Exam,
    Product,
    Surgical
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    icon() {
      return (
        {
          PROCEDURE: Procedure,
          RETURN: Return,
          EXAM: Exam,
          TELEMEDICINE: Telemedicine,
          APPOINTMENT: Appointment,
          SURGICAL: Surgical
        }[this.type] || Product
      )
    },
    size() {
      return (
        {
          EXAM: 'sm',
          APPOINTMENT: 'lg'
        }[this.type] || 'md'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  fill: var(--blue-500);

  path {
    fill: var(--blue-500);
  }
}
.sm {
  margin-right: 5px;
  width: 20px;
}
.md {
  margin-right: 5px;
  width: 20px;
}
.lg {
  margin-right: 5px;
  width: 20px;
}
</style>
